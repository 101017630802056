





































































































































































































































































































































import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator';
import { banks } from '@/filters/banks';
import { priceProject } from '@/filters/price-project';
import FacturacionDialog from '../DialogDetailFactura.vue';
import ValidateFacturacionDialog from './DialogValidateFactura.vue';
import FormFacturacionDialog from '../DialogFormFactura.vue';
import { minuteModule } from '@/store/modules/minute';
import { readMore } from '@/filters/read-more';
import IsUser from '@/mixins/is-user';

@Component({
    filters: { banks, priceProject, readMore },
    components: {
        FacturacionDialog,
        ValidateFacturacionDialog,
        FormFacturacionDialog
    }
})
export default class MinuteTable extends Mixins(IsUser) {
    @Prop({ required: true }) readonly tableData!: {
        data: any[];
        total: number;
    };
    @Prop({ type: String, default: 'bg-primary text-white' })
    readonly headerRowClassName!: string;

    get state() {
        return this.$route.params.state;
    }

    rowSelected: any = {};
    dialogFacturacion = false;
    dialogValidation = false;
    dialogFormFacturacion = false;
    validateType = 1;

    openDialogDetail(row: any) {
        this.rowSelected = row;
        this.dialogFacturacion = true;
    }

    openDialogFormFacturacion(row: any) {
        this.rowSelected = row;
        this.dialogFormFacturacion = true;
    }

    changePage(page: number) {
        this.$router.push({
            query: { ...this.$route.query, page: page.toString() }
        });
        minuteModule.setFilter({ page });
        minuteModule.index();
    }

    changeOption(row: any, index: number, state: number) {
        this.tableData.data[index].state = state;
        this.validateType = state;
        this.rowSelected = row;
        this.dialogValidation = true;
    }

    closeDialogValidation(minute: any) {
        minute.state = 0;
        this.dialogValidation = false;
    }
}
