





























































































import { Component, Watch, Mixins } from 'vue-property-decorator';
import { minuteModule } from '@/store/modules/minute';
import FilterTable from '@/components/AppoinmentTable/Filter.vue';
import MinuteTable from '@/components/Minute/Table.vue';
import { FilterAppointment } from '@/interfaces/filter';
import FilterTableMixin from '@/mixins/filter-table';

@Component({
    components: { FilterTable, MinuteTable }
})
export default class Minute extends Mixins(FilterTableMixin) {
    get activeButton() {
        return this.$route.params.state === 'incompleta' ? true : false;
    }

    get tableData() {
        return minuteModule.response;
    }

    get title() {
        if (this.isUser) {
            return 'Gestión de Minutas';
        }
        return 'Seguimiento de minutas de mis agentes';
    }

    get blocked() {
        return this.isUser ? ['customer', 'user'] : ['cliente'];
    }

    hiddenLegend = true;

    filterData(data: FilterAppointment) {
        minuteModule.setFilter(data);
        minuteModule.index();
    }
}
