





































































import { Component, Mixins, Vue, Prop } from 'vue-property-decorator';
import { minuteModule } from '@/store/modules/minute';

@Component
export default class DialogValidacionFactura extends Vue {
    @Prop({ required: true }) minute!: any;
    @Prop({ required: true }) typevalidate!: number;
    step = 1;

    close() {
        this.$emit('close', this.minute);
    }

    async store() {
        try {
            await minuteModule.validateBilling({
                state: this.typevalidate,
                id: this.minute._id
            });
            await minuteModule.index(true);
            this.step++;
        } catch (error) {
            this.$notify.error({
                title: 'Error',
                message:
                    typeof error.mensaje === 'string'
                        ? error.mensaje
                        : 'Hemos tenido algunos inconvenientes'
            });
        }
    }
}
