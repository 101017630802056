



















































































































































































































































































































































































































import { Component, Ref, Vue, Prop, Watch } from 'vue-property-decorator';
import { agentModule } from '@/store/modules/agent';
import { minuteModule } from '@/store/modules/minute';
import Swal from 'sweetalert2';
@Component
export default class DialogValidacionFactura extends Vue {
    @Prop({ required: true }) minute!: any;
    @Ref() provider!: any;

    form: any = {
        accounts: [{ currency: 1 }, { currency: 2 }]
    };

    options = [
        {
            value: 'BCP',
            label: 'BCP'
        },
        {
            value: 'BBVA',
            label: 'BBVA'
        },
        {
            value: 'INTERBANK',
            label: 'INTERBANK'
        },
        {
            value: 'SCOTIABANK',
            label: 'SCOTIABANK'
        },
        {
            value: 'BAN-BIF',
            label: 'BAN BIF'
        },
        {
            value: 'BANCO-PICHINCHA',
            label: 'BANCO PICHINCHA'
        },
        {
            value: 'GNB',
            label: 'GNB'
        },
        {
            value: 'BANCO-DE-LA-NACION',
            label: 'BANCO DE LA NACIÓN'
        },
        {
            value: 'MI-BANCO',
            label: 'MI BANCO'
        },
        {
            value: 'BANCO-COMERCIO',
            label: 'BANCO COMERCIO'
        },
        {
            value: 'OTRO',
            label: 'OTRO'
        }
    ];

    step = 1;

    get isBroker() {
        return this.minute.brokerId ? true : false;
    }

    get broker() {
        return this.minute.brokerId
            ? this.minute.agent.parent
            : this.minute.brokerId;
    }

    @Watch('minute', { immediate: true })
    handleMinute() {
        this.setInfo();
    }

    close() {
        this.$emit('close', true);
    }

    next() {
        this.step++;
    }

    async store() {
        try {
            await minuteModule.validateDataPayment(this.minute._id);
            await minuteModule.index(true);
            if (!this.isBroker) {
                await agentModule.editAgent(this.form);
            }
            this.step++;
        } catch (error) {
            if (typeof error.mensaje !== 'string' && error.mensaje.errors) {
                this.provider.applyResult({
                    errors: ['Este ruc ya se encuentra registrado.'],
                    valid: false,
                    failedRules: {}
                });
            } else {
                this.$notify.error({
                    title: 'Error',
                    message:
                        typeof error.mensaje === 'string'
                            ? error.mensaje
                            : 'Hemos tenido algunos inconvenientes'
                });
            }
        }
    }

    get user() {
        return agentModule.agent;
    }

    get isReadonly() {
        return !!this.minute.isvalidate && this.isBroker;
    }

    setInfo() {
        this.step = this.isReadonly ? 3 : 1;
        if (this.isBroker) {
            this.form = {
                ruc: this.broker.ruc,
                logo: this.broker.logo,
                datosRemitente: this.broker.datosRemitente,
                corporateName: this.broker.corporateName,
                accountNumber: this.broker.accountNumber,
                accounts: this.broker.accounts
            };
        } else {
            this.form = {
                ruc: this.user?.ruc,
                logo: this.user?.logo,
                datosRemitente: this.user?.datosRemitente,
                corporateName: this.user?.corporateName,
                accountNumber: this.user?.accountNumber,
                accounts: this.user?.accounts
            };
        }
    }
}
