







































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { priceProject } from '@/filters/price-project';
@Component({
    filters: { priceProject }
})
export default class DialogFacturacion extends Vue {
    @Prop({ required: true }) readonly minute!: any;

    get typeCurrency() {
        return this.minute.typeCurrency == 1 ? 'S/' : '$/';
    }

    close() {
        this.$emit('close', true);
    }
}
